:root {
    --primary-color: #FF4F18;
    --dark-orange: rgba(255, 79, 24, 0.50);
    --brown: rgba(255, 79, 24, 0.20);

    --background-color: #F5F5F5;
    --arctic-green-1: #F0FCF9;

    --danger-red-1: #FFE3E3;
    --danger-red-2: #E03131;

    --black: #141517;
    --secondary-black: #1C1E22;

    --gray: #ADBACC;
    --dark-gray: #7187A8;
    --light-gray: #F2F4F7;
    --snow-gray: #737691;
    --snow-gray-2: #F0F1F4;
    --snow-gray-3: #D0D2DC;
    --snow-gray-7: #737691;
  }