.footer {
    box-sizing: border-box;
    width: 100%;
    height: 80px;
    padding: 20px 36px 40px;
    display: flex;
    align-items: center;
    gap: 40px;
}

.wrights {
    width: 121px;
    height: 18px;
    margin-top: 4px;
}

.footer__links {
    display: flex;
    gap: 20px;
}

.text {
    color: var(--secondary-black);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.footer__language-container {
    display: flex;
    flex-direction: row-reverse;
    flex-grow: 1;
}

.footer__language {
    display: flex;
    gap: 9px;
}

.language {
    width: 20px;
    height: 20px;
}

.link {
    text-decoration: none;
}

.link:hover {
    cursor: pointer;
}