/* Общие стили страницы */
.trip-creation-page {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 24px 36px;
    gap: 137px;
}

/* Верхняя панель с кнопкой и степпером */
.trip-main {
    display: flex;
    flex-direction: column;
    gap: 64px;
}

.trip-nav, .trip-content {
    display: flex;
    flex-direction: column;
    gap: 52px;
}

.trip-fields {

}

.buttons {
    display: flex;
    gap: 14px;
}