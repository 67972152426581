.stepper-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    margin-left: -94px;
}

.stepper {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.stepper-item {
    display: flex;
    align-items: center;
    gap: 16px;
}

.step {
    width: 38px;
    height: 38px;
    padding: 6px;
    border-radius: 20px;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.4px;
}

.step:hover {
    cursor: pointer;
}

.default {
    border: 1px solid var(--snow-gray-3);
    background-color: var(--snow-gray-2);
    color: var(--snow-gray-7);
}

.active {
    border: none;
    background-color: var(--primary-color);
    color: var(--arctic-green-1);
}

.finished {
    border: 1px solid var(--dark-orange);
    background-color: var(--brown);
}

.divider {
    height: 2px;
    width: 320px;
    background-color: var(--snow-gray-3);
    border: none;
    margin: 0;
}

.divider.completed {
    background-color: var(--primary-color);
}

.stepper-text {
 display: flex;
 gap: 311px;
 margin-left: 94px;
}

.text-group {
 display: flex;
 flex-direction: column;
 gap: 8px;
}

.text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    letter-spacing: 0.4px;
}

.upper {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
}

.lower {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;

}

.chosen {
 color: var(--secondary-black);
}

.inactive {
    color: var(--snow-gray-7);
}