.header {
    color: var(--black);
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    margin: 0;
}

.main-header {
    font-size: 32px;
    line-height: normal; 
}

.section-header {
    font-size: 20px;
    line-height: normal; 
}

.input-header {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 4px;
}

.trip-card-header {
    font-size: 20px;
    line-height: normal;
    color: var(--primary-color);
}