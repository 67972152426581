.form-input__label {
    display: block;
    width: 100%;
}
  
  
.form-input__field {
    width: 100%;
    max-width: 502px;
    height: 36px;
    padding: 8px 12px;
    font-size: 16px;
    color: var(--black);
    border: 1px solid var(--gray);
    border-radius: 5px;
    box-sizing: border-box;
}

.form-input {
    width: 100%;
}
  
  
.form-input__field::placeholder {
    color: var(--gray);
    opacity: 1;
}

.form-input__field:focus {
    outline: none;
    border-color: var(--black);
}